class Quiz {
  constructor() {
    const el = document.querySelector('#quiz');

    if (!el) return;

    this.el = el;
    this.el_status = el.querySelector('.js-status');
    this.btn_prev = el.querySelector('.js-btn-prev');
    this.btn_next = el.querySelector('.js-btn-next');
    this.btn_submit = el.querySelector('.js-btn-submit');
    this.steps = Array.from( el.querySelectorAll('.js-step') );
    this.step = 0;

    this.setStep();
    this.btns();
    this.setStatus();
    this.fields();
    this.checkFields();
  }

  btns() {
    this.btn_next.addEventListener('click', e => {
      if (!e.currentTarget.disabled) {
        this.step += 1;

        if (this.step > (this.steps.length - 1)) {
          this.step = this.steps.length - 1;
        }

        this.setStep();
        this.checkFields();
      }

      return;
    });

    this.btn_prev.addEventListener('click', e => {
      if (!e.currentTarget.disabled) {
        this.step -= 1;

        if (this.step < 0) {
          this.step == 0;
        }

        this.setStep();
        this.checkFields();
      }

      return;
    });

    this.btn_submit.addEventListener('click', e => {
      const interval = setInterval(() => {
        if (this.el.classList.contains('is-success')) {
          clearInterval(interval);
          this.step = this.steps.length - 1;
          this.setStep();
        }
      }, 100);
    });
  }

  fields() {
    const selects = Array.from(this.el.querySelectorAll('select, input'));

    selects.forEach(item => {
      item.addEventListener('change', e => {
        setTimeout(() => {
          this.checkFields();
        }, 200);
      });
    });
  }

  setStep() {
    this.steps.forEach(item => item.style.display = 'none');
    this.steps[this.step].style.display = 'block';

    if (this.step == 0) {
      this.btn_prev.style.display = 'none';
      this.btn_prev.setAttribute('disabled', true);
      this.btn_next.removeAttribute('disabled');
      this.setStatus();
      return;
    }

    if (this.step == (this.steps.length - 2)) {
      this.btn_next.style.display = 'none';
      this.btn_next.setAttribute('disabled', true);

      this.btn_submit.style.display = 'flex';
      this.setStatus();
      return;
    }

    if (this.step < (this.steps.length - 1)) {
      this.btn_submit.style.display = 'none';
      this.btn_next.style.display = 'flex';
      this.btn_next.removeAttribute('disabled');
      this.setStatus();
    }

    if (this.step > 0 ) {
      this.btn_prev.style.display = 'flex';
      this.btn_prev.removeAttribute('disabled');
      this.btn_next.setAttribute('disabled', true);
      this.setStatus();
    }
  }

  setStatus() {
    const sequel = 100 / (this.steps.length);
    const value = (this.step + 1) * sequel;
    this.el_status.style.width = value + '%';
  }

  checkFields() {
    const selects = Array.from(this.steps[this.step].querySelectorAll('select'));
    const inputs = Array.from(this.steps[this.step].querySelectorAll('input'));
    const fullLenght = selects.length + inputs.length;

    let active = 0;

    if (selects.length > 0) {
      selects.forEach(item => {

        if (item.value != 0) {
          active += 1;
        }
      });
    }

    if ( inputs.length > 0 ) {
      const valid = AppBouncer.validateAll(this.steps[this.step]);

      if (valid.length == 0) {
        active += inputs.length;
      }
    }

    if (active == fullLenght) {
      this.btn_next.removeAttribute('disabled');
    } else {
      this.btn_next.setAttribute('disabled', true);
    }
  }
}

export default Quiz;
