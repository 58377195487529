class GoogleMaps {
  constructor() {
    if (!document.querySelector('.js-g-map')) return;

    const check = setInterval(() => {
      if (typeof google !== undefined) {
        clearInterval(check);
        setTimeout(() => {
          this.drawMap();
        }, 300);
      }
    }, 300);
  }

  drawMap() {
    const elems = Array.from(document.querySelectorAll('.js-g-map'));

    elems.forEach(mapContainer => {
      const mapCoords = JSON.parse(mapContainer.dataset.coords);

      const pos = {
        lat: mapCoords[0],
        lng: mapCoords[1]
      };

      const map = new google.maps.Map(mapContainer, {
        center: pos,
        zoom: 16
      });

      const marker = new google.maps.Marker({
        position: pos,
        map: map
      });
    });

  }
}

export default GoogleMaps;
