class Slider {
  constructor() {
    this.slHero();
    this.slPack();
    this.slPartner();
    this.slOpinion();
    this.slGallery();
    this.slReels();
  }

  slHero() {
    const el = document.querySelector('#sl-hero');

    if (!el) return;

    const btn_prev = el.parentElement.querySelector('.js-prev');
    const btn_next = el.parentElement.querySelector('.js-next');
    const images = document.querySelectorAll('.js-sl-hero-pic');

    new Swiper(el, {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 6000,
      },
      slidesPerView: 'auto',
      navigation: {
        prevEl: btn_prev,
        nextEl: btn_next,
        disabledClass: 'is-disabled'
      },
      pagination: {
        el: '.js-bullets',
        type: 'bullets',
        bulletActiveClass: 'is-active',
        bulletClass: 'bullet',
        modifierClass: '',
        clickable: true,
        clickableClass: 'is-clickable'
      },
      on: {
        init: sw => {
          images.forEach(item => item.style.opacity = 0);
          images[sw.activeIndex].style.opacity = 0.27;
        },

        slideChange: sw => {
          images.forEach(item => item.style.opacity = 0);
          images[sw.activeIndex].style.opacity = 0.27;
        }
      }
    });
  }

  slPack() {
    const el = document.querySelector('#sl-pack');

    if (!el) return;

    const btn_prev = el.parentElement.querySelector('.js-prev');
    const btn_next = el.parentElement.querySelector('.js-next');
    const bullets = el.parentElement.querySelector('.js-bullets');

    new Swiper(el, {
      slidesPerView: 'auto',
      navigation: {
        prevEl: btn_prev,
        nextEl: btn_next,
        disabledClass: 'is-disabled'
      },
      pagination: {
        el: bullets,
        type: 'bullets',
        bulletActiveClass: 'is-active',
        bulletClass: 'bullet'
      },
    });
  }

  slReels() {
    const el = document.querySelector('#sl-reels');

    if (!el) return;

    const btn_prev = el.parentElement.querySelector('.js-prev');
    const btn_next = el.parentElement.querySelector('.js-next');
    const bullets = el.parentElement.querySelector('.js-bullets');

    new Swiper(el, {
      slidesPerView: 'auto',
      navigation: {
        prevEl: btn_prev,
        nextEl: btn_next,
        disabledClass: 'is-disabled'
      },
      pagination: {
        el: bullets,
        type: 'bullets',
        bulletActiveClass: 'is-active',
        bulletClass: 'bullet'
      },
    });
  }

  slPartner() {
    const el = document.querySelector('#sl-partner');

    if (!el) return;

    const btn_prev = el.parentElement.querySelector('.js-prev');
    const btn_next = el.parentElement.querySelector('.js-next');

    new Swiper(el, {
      slidesPerView: 'auto',
      navigation: {
        prevEl: btn_prev,
        nextEl: btn_next,
        disabledClass: 'is-disabled'
      }
    });
  }

  slOpinion() {
    // const el = document.querySelector('#sl-opinion');
    const elems = Array.from(document.querySelectorAll('.js-sl-opinion'));

    if (elems.length == 0) return;

    elems.forEach(el => {
      const btn_prev = el.parentElement.querySelector('.js-prev');
      const btn_next = el.parentElement.querySelector('.js-next');
      const bullets = el.parentElement.querySelector('.js-bullets');

      new Swiper(el, {
        slidesPerView: 'auto',
        pagination: {
          el: bullets,
          type: 'bullets',
          bulletActiveClass: 'is-active',
          bulletClass: 'bullet'
        },
        navigation: {
          prevEl: btn_prev,
          nextEl: btn_next,
          disabledClass: 'is-disabled'
        }
      });
    });

  }

  slGallery() {
    const el = document.querySelector('#sl-gallery');

    if (!el) return;

    const btn_prev = el.parentElement.querySelector('.js-prev');
    const btn_next = el.parentElement.querySelector('.js-next');
    const bullets = el.parentElement.querySelector('.js-bullets');

    new Swiper(el, {
      slidesPerView: 'auto',
      pagination: {
        el: bullets,
        type: 'bullets',
        bulletActiveClass: 'is-active',
        bulletClass: 'bullet'
      },
      navigation: {
        prevEl: btn_prev,
        nextEl: btn_next,
        disabledClass: 'is-disabled'
      }
    });
  }
}

export default Slider;
