import Slider from "./modules/Slider.js";
import Form from './modules/Form.js';
import Quiz from './modules/Quiz.js';
import GoogleMaps from './modules/GoogleMaps.js';
import zenScroll from "zenscroll";

class App {
  constructor() {
    svg4everybody();

    new Slider();
    new Quiz();
    new Form();
    new GoogleMaps();

    this.lightgallery();
    this.toggler();
    this.unimodal();
    this.flatpickr();
    this.scrollto();
    this.expand();
  }

  lightgallery() {
    const elems = Array.from(document.querySelectorAll('.js-lightgallery'));

    if (elems.length == 0) return;

    elems.forEach(elem => {
      lightGallery(elem, {
        selector: '.js-lightgallery-item',
        download: false,
        loop: false
      });
    });
  }

  toggler() {
    const toggle = new Unitoggle({
      onOpen: function (tab) {
        if (tab.id == 'tab-form-opinion') {
          zenscroll.to(tab);
        }
      },
      onClose: function(tab) {}
    });
  }

  unimodal() {
    const modal = new Unimodal({
      scrollWindow: false,
      onOpen: ( modal, button ) => {
        if (modal.id == 'modal-procedure-clinic') {
          if (button.hasAttribute('data-clinic')) {
            modal.querySelector('input[name="clinic"]').value = button.dataset.clinic;
          }
        }

        if (modal.id == 'modal-callback') {
          if (button.hasAttribute('data-spec')) {
            modal.querySelector('input[name="spec"]').value = button.dataset.spec;
          }
        }

        if ( modal.id == 'modal-video' ) {
          const src = button.getAttribute('data-src');
          const iframe = modal.querySelector('iframe');
          if ( src && iframe ) {
            iframe.src = src;
          }
        }

        if ( modal.id == 'modal-reel' ) {
          const src = button.getAttribute('data-src');
          const el = modal.querySelector('.js-cnt');
          if ( src && el ) {
            el.innerHTML = document.getElementById(src).innerHTML;
          }
        }
      },
      onClose: ( modal ) => {
        if (modal.id == 'modal-callback') {
          modal.querySelector('input[name="spec"]').value = '';
        }

        if ( modal.id == 'modal-video' ) {
          const iframe = modal.querySelector('iframe');
          if ( iframe ) {
            iframe.src = '';
          }
        }

        if ( modal.id == 'modal-reel' ) {
          const el = modal.querySelector('.js-cnt');
          if ( el ) {
            el.innerHTML = '';
          }
        }
      }
    });
  }

  flatpickr() {

    if (document.querySelector('#date-range')) {
      const el = document.querySelector('#date-range');
      flatpickr(el, {
        mode: "range",
        minDate: "today",
        dateFormat: "d.m.Y",
        allowInput: true,
        locale: el.getAttribute('data-lang')
      });
    }

    if (document.querySelector('#date-time')) {
      const el = document.querySelector('#date-time');
      flatpickr(el, {
        mode: "single",
        minDate: "today",
        dateFormat: "d.m.Y, H:i",
        enableTime: true,
        minuteIncrement: 15,
        allowInput: true,
        locale: el.getAttribute('data-lang')
      });
    }
  }

  scrollto() {
    const href = window.location.href;

    if (href.includes('commpage')) {
      const el = document.querySelector('#comments');

      if (el) {
        zenscroll.to(el);
      }
    }
  }

  expand() {
    const elems = Array.from(document.querySelectorAll('.js-expand'));

    if (elems.length == 0) return;

    elems.forEach(elem => {
      const body = elem.querySelector('.js-body');

      if (body) {
        const max_height = parseInt(body.getAttribute('data-expand'));

        if (body.offsetHeight > max_height) {
          elem.classList.add('is-short');
        } else {
          elem.classList.remove('is-short');
        }
      }
    })
  }
};

document.addEventListener('DOMContentLoaded', () => {
  new App();
});
